<template>
  <div>
    <leaf-let-map
      :trips-data="jobs"
      :follow-data="followRoute"
    >
      <template #content>
        <div>
          <v-card class="map-job-content">
            <v-card-title class="d-flex justify-space-between align-center">
              <div>
                <div class="d-flex -justify-space-between align-center">
                  <div>
                    <v-btn
                      color="dark"
                      text
                      x-small
                      @click="redirect()"
                    >
                      <v-icon> mdi-arrow-left </v-icon>
                    </v-btn>
                  </div>
                  <div>Job Activity</div>
                </div>
                <div class="title">
                  {{ vehcileName }}
                </div>
              </div>
              <div
                class="open-link"
                @click="() => (isOpen = !isOpen)"
              >
                <v-icon>mdi-menu</v-icon>
              </div>
            </v-card-title>
            <v-card-text v-if="isOpen">
              <div
                v-for="(r, i) in jobs"
                :key="i"
                class="tag"
              >
                <div class="d-flex justify-space-between align-center">
                  <div class="content-info">
                    <div>
                      {{ r.location_name }}
                    </div>
                    <div class="text-muted">
                      <span v-if="r.status == 'progress'">
                        {{ r.started_at | formatDate }}
                      </span>
                      <span v-else-if="r.status == 'completed'">
                        {{ r.completed_at | formatDate }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="status"
                    :class="r.status"
                  >
                    <v-icon>
                      {{
                        r.status == "completed" ? "mdi-check-all" : "mdi-circle"
                      }}
                    </v-icon>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </template>
    </leaf-let-map>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {
    LeafLetMap: () =>
      import("@/views/modules/route-planing/sub-screen/LeafMap1.vue"),
  },
  data() {
    return {
      isOpen: true,
    };
  },
  computed: {
    ...mapGetters({
      loading: "routePlaning/getLoading",
      jobRoutes: "routePlaning/getJobRoutes",
    }),
    vehcileName() {
      if (this.jobRoutes?.jobs?.length > 0) {
        return this.jobRoutes?.jobs[0]?.vehicle_name;
      }
      return "";
      //   return this.jobRoutes?.jobs[0]?.vehicle_name;
    },
    jobs() {
      const arr = [];
      if (this.jobRoutes?.jobs) {
        this.jobRoutes.jobs.forEach((el) => {
          el.route = el.route.coordinates.map((r) => r.reverse());
          arr.push(el);
        });
      }
      return arr;
    },
    followRoute() {
      return this.jobRoutes?.followed_route || [];
    },
    // path() {
    //   const arr = this.jobRoutes?.jobs.map(r=> );
    //   return [];
    // },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const params = {
        date: this.$route?.query.date,
        vehicle_id: this.$route?.params?.id,
      };
      await this.$store.dispatch("routePlaning/jobRoutes", params);
    },

    redirect() {
      return this.$router.push({ name: "route_planning" });
    },
  },
};
</script>
<style lang="sass" scoped>
.map-job-content
    width: 250px

    .v-card__title
        outline:  1px solid lightgrey


    .v-card__text
        // padding-top: 1rem
        height: 75vh
        overflow-y: auto
        padding: 0
        .tag
            border-top: 1px solid lightgrey
            padding: 0.8em 0.5rem

            .content-info
                font-size: 12px


            .status
                i
                    font-size: 14px

            // status color
            .pending
                i
                    color: #f3ef35
            .progress
                i
                    color: #20a390
            .completed
                i
                    color: #1867c0
            // status color

.text-muted
    color: grey
    font-size: 11px
</style>
